//
//
//
//

import Vue from 'vue';
import { useStoryblok } from '@storyblok/nuxt-2';
import TrackingService from '@animaapp/anima-tracking';
import { onMounted, useContext, useRoute, useRouter } from '@nuxtjs/composition-api';
import { setMetaTag } from '@/utils/setMetaTag';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const router = useRouter();
  const {
    isDev,
    query
  } = useContext();
  const slug = route.value.params.pathMatch;
  const version = query.value._storyblok || isDev || process.env.preview ? 'draft' : 'published';
  const {
    story
  } = useStoryblok(slug || 'home', {
    version,
    resolve_relations: `global_reference.reference`
  }); // The mobile main nav hides the scrollbars when open, so we need to make sure
  // we restore them when navigating to a new page while the mobile nav is open

  router.afterEach(() => document.body.style.overflow = '');
  onMounted(() => {
    if (route.value.fullPath.startsWith('/global/')) {
      setMetaTag("robots", "noindex");
    }

    const config = {
      web: true,
      clientId: 'com.animaapp.website'
    };
    Vue.prototype.$tracking = new TrackingService(config);
  });
  return {
    story
  };
};

export default __sfc_main;
