export const setMetaTag = (name, content) => {
  let metaTag = document.querySelector(`meta[name="${name}"]`);
  if (metaTag) {
    metaTag.content = content;
  } else {
    metaTag = document.createElement('meta');
    metaTag.name = name;
    metaTag.content = content;
    document.head.appendChild(metaTag);
  }
};
